.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.entrydot{
  position:fixed;
  margin-left:auto;
  margin-right:auto;
  top:50%;
  width:10px;
  height:10px;
  border-radius: 5px;
  background-color: white;
}

.mainstuff{
  width:100%; 
  height:100vh;
  transition:2s 3s ease;

}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
