.contact-background{
    background-color: #051805;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:10%;
}

.description-section{
    color: #f8f0e9;
    padding: 10%;
    font-family: Nanum Myeongjo;
    font-size: 18pt;
    line-height: 2.5rem;
    animation: fadeInHome 5s;
    text-align: left;
    background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.7)),url('../Images/hands.jpg') no-repeat;
    background-size: cover;
    background-color: #051805;
    background-position: center;
    margin:0;
}
.contactInfo{
    background-color: #051805;
    color:#f8f0e9;
    padding-bottom: 10%;;
}

.contact-section{
    background-color: #051805;
    color:#f8f0e9;
    width:100%;
    padding:5%;
    font-family: Nanum Myeongjo;
    padding-bottom:12%;
}
.contact-section>h3{
    font-weight: 200;
}

.contactInfo>h3{
    font-weight: 200;
    background-color: #051805;
    color:#f8f0e9;
    width:90%;
    padding:5%;
    font-family: Nanum Myeongjo;
}

.emailLink{
    text-decoration: none;
    font-weight: 200;
    background-color: #051805;
    color:#f8f0e9;
    width:100%;
    font-family: Nanum Myeongjo;
}

.bigger{
    font-size:24pt;
}

.session-types{
    margin-left: 175px;
}